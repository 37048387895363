import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CodeHighlighter from "../components/CodeHighlighter"

export const curlCommand = `curl --location --request \n POST 'https://api.emailkick.com/mail/send' \
\n--data-raw '{
    "APIKEY"  \    : "<<YOUR-APIKEY>>",
    "ACCESSID"\    : "<<YOUR ACCESSID>>",
    "to"\          : "someone@example.com",
    "from"\        : "hi@yourdomain.com",
    "templateId"\  : "<<TEMPLATE_ID>>",
    "variables"\   : {
        "full_name" : "Elon Musk" ,
        "company"   : "spacex"
    }
}'`


const HistoryBlockData = [{
  id:0,
  email: 'at*****92@example.com',
  time: '2 mins ago',
  templateName: 'Welcome Email',
  status: 'sent',
  pos:-30,
}, {
  id:1,
  email: 'yy*****oq@gmail.com',
  time: '4 days ago',
  templateName: 'Email validation mail',
  status: 'bounce',
  pos:25
}, {
  id:2,
  email: 'as*****bz@x.com',
  time: '1 mins ago',
  templateName: 'Payment Invoice',
  status: 'processing',
  pos:0
}]



const HistoryEmailBlock = (props) => {
  return (
    <div className="flex justify-center" style={{transform: `translateX(${props.pos}px)` }}>
      <span  data-sal='slide-up' data-sal-delay="300" data-sal-ease="ease" style={{ width:'340px',  }} className="rounded-lg inline-block bg-white glassbox-non-radius bg-opacity-80  flex-col py-4 px-4 border border-gray-100 shadow-sm mb-3">
            <div className="text-gray-500 text-sm flex items-center justify-between">
              <span>{props.email}</span> <span className="ml-4 text-gray-400 text-xs">{props.time}</span>
              </div>
            <div className="text-base font-medium text-gray-700 mt-1">{props.templateName}</div>

            { props.status == 'sent' ? <div className="mt-3">
              <span className="bg-green-100 inline-block text-green-800 px-2 py-1 border border-green-200 rounded-md font-medium text-xs">Email Sent</span>
            </div> : null }

            { props.status == 'bounce' ? <div className="mt-3">
              <span className="bg-red-100 inline-block text-red-800 px-2 py-1 border border-red-200 rounded-md font-medium text-xs">Bounced</span>
            </div> : null }

            { props.status == 'processing' ? <div className="mt-3">
              <span className="bg-yellow-100 inline-block text-yellow-800 px-2 py-1 border border-yellow-200 rounded-md font-medium text-xs">Processing</span>
            </div> : null }
            
          </span>
    </div>
    
  )
}


const RotatingText = (props) => {

  const elRefs = React.useRef([]);

  const defaultcss = 'home__hero__title__rotating-copy__item hero-text-animated'
  const isVisible = 'home__hero__title__rotating-copy__item hero-text-animated is-visible'
  const wasVisible = 'home__hero__title__rotating-copy__item hero-text-animated was-visible'

  const [texts, setTexts] = useState([
    { name:'emails using AWS SES', width:'320px', css:'home__hero__title__rotating-copy__item hero-text-animated is-visible' },
    { name:'transactional emails ', width:'460px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
    { name:'welcome emails via AWS SES', width:'460px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
    { name:'forget password links', width:'340px', css:'home__hero__title__rotating-copy__item hero-text-animated' }, 
    { name:'validation emails via AWS SES', width:'630px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
    { name:'beta invites emails via AWS SES', width:'200px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
    { name:'user reports emails', width:'200px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
    { name:'email invitations', width:'200px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
    { name:'payment links', width:'200px', css:'home__hero__title__rotating-copy__item hero-text-animated' },
  ]);
  const [pageIndex, setIndex] = useState(0);
  useEffect(() => {

    const timer = setInterval(() => {
      const textsLength = texts.length;
      if(pageIndex == -1){
        changeStatus(0);
        setIndex(indexval => 0);
        return 
      }

      if(pageIndex+1 == textsLength) {
        changeStatus(0, pageIndex)
        setIndex(indexval =>  0);
        return 
      }
      changeStatus(pageIndex+1, pageIndex);
      setIndex(indexval => indexval+1);
    }, 4500)
    
    return () => {
      clearInterval(timer);
    }
  }, [pageIndex])

  const changeStatus = (currentIndex, prevIndex) => {
    const textscopy = texts;   
    if(prevIndex > -1) {
      textscopy[prevIndex].css = wasVisible;
    }
    textscopy[currentIndex].css = isVisible;
    setTexts([...textscopy]);
  }

  return (
  <div className="hero-rotating-text-area inline-block relative pointer-events-none">
    {/* <div className="absolute -top-10 -mt-20 -left-20 z-0 opacity-30">
      <img src="/images/blurred_2.png" className="transform scale-150 z-0" />
    </div> */}
    <div className="hero-rotating-text-area--items z-10">
      {
        texts.map((text, index) => (
          <span ref={ref => elRefs.current[index] = ref} className={text.css} data-home-target="rotatingCopyItem">
                  {text.name}.
          </span>
        ))
      }  
    </div>
    <span className="hero-rotating-text-area--placeholder z-10 opacity-0">emails using AWS SES</span>

    <span style={{ width: pageIndex > -1 && elRefs.current.length > 0 ? elRefs.current[pageIndex].offsetWidth : texts[0]['width'] }} className="hero-rotating-text-area--underline absolute h-2 left-0 z-0 bottom-1 rounded-md bg-red-500 opacity-50"></span>
  </div>
)}


const IndexPage = () => (
  <Layout>
    <SEO title="Simple transactional email designer and API for Amazon SES" description="Transactional Email Designer for Amazon SES that enable you to send and track emails. Design responsive emails using drag and drop editor and send dynamic emails to your customers using simple API" />
    <div className=" hero-b">
      <div className="max-width-4 mx-auto pb4 pt4 px2">
        <div className="hero text-left">
          <h1 className="flex flex-col">Easily design and send beautiful <br /><RotatingText /></h1>
          <h3 className="display big line-height-3 pb2 mb3 text-base">Build responsive email templates using drag and drop that works across clients. Send your emails via your <span className="px-1 py-1 font-medium bg-yellow-200 rounded-md">Amazon SES</span> account using few lines of code.</h3>
          <div className="flex md:items-center md:flex-row text-center flex-col">
            <a href='https://app.emailkick.com/register/' rel="nofollow" className=" bg-red-500 hover:bg-red-600 text-white strong px-8 py-4 md:text-lg text-sm font-medium rounded-xl  duration-100 shadow-lg">Connect your AWS SES</a>
            <span className="block ml-6 md:mt-0 mt-3 text-gray-500">Your first 100 emails is on us. No credit card needed! </span>

            {/* <a className="sec-cta">Watch Video</a> */}
          </div>
          {/* <div className="mt-4"><a href="https://www.producthunt.com/posts/emailkick?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-emailkick" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=331043&theme=light" alt="Emailkick - Simple email designer and API for AWS SES users 💌 | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a></div> */}

          {/* <div><Link className="text-blue-800 text-base font-medium mt-4" to="/">Schedule a demo</Link></div> */}
        </div>  



      
      </div>


      <div className="w-full ">


      <div className="max-width-4 mx-auto px2 ">
        <div className="hero-img">
          <img className='show-xs md-hide lg-hide' alt="EmailKick drag and drop dashboard" src={'/front-img.png'} />
          <div className="xs-hide landing-video" id="player-overlay">
              <div className="absolute w-48 h-48 rounded-full bg-red-600 bg-opacity-20 rounded-full top-10 -left-20 glassbox-non-radius  z-20"></div>
              <div className="absolute w-48 h-48 rounded-full bg-blue-700 bg-opacity-20 rounded-full bottom-10 -right-20 glassbox-non-radius  z-20"></div>

              {/* <div className="landing-circle-1 glassbox opacity-70 bg-opacity-50 z-20"></div> */}
              {/* <div className="landing-circle-2 z-20"></div> */}
              <div className="blurred-class relative max-w-screen">
                  <img alt="blurred-bg" className='absolute transform -mt-20 -left-20 -ml-20' src="/images/blurred_1.png" />
                  <img alt="blurred-bg" className='absolute right-20 ml-30 -mt-32 transform ' src="/images/blurred_2.png" />
                  <img alt="blurred-bg" className='absolute right-32 transform opacity-10 ' src="/images/blurred_1.png" />
                  <img alt="blurred-bg" className='absolute right-5 -mt-20 transform  opacity-50' src="/images/blurred_2.png" />
              </div>
              <video className="z-10 relative shadow-2xl rouneded-lg" width="100%" video="100%" poster={'/video-poster.jpg'} loop muted autoPlay style={{ width:'100%', height:'100%' }}>
                <source className="rouneded-lg" src="/emailkick-dashboard.mp4" />
              </video>    
            </div>  
        </div>
      </div>

      </div>
      
    </div>



    <section className="feat-section mt-24 md:mt-48">
      <div className="mx-auto max-w-6xl mt-24 py-8 px-4 md:px-14">
        <div className="mx-auto max-w-2xl mb-8"> 
          <h2 className="md:text-center text-3xl font-medium text-heading-blue">Hassle free API that does all the work for you.</h2>
          <p className="text-gray-600 display  text-lg md:text-center mt-4 leading-normal">Stop writing endless code and services to send simple emails. <br /> Emailkick API handles all the <span className="opacity-100">💩</span> work to make sure your email reaches your user. From HTML generation to sending email using AWS SES</p>
        </div>

        <div className="blurred-class relative max-w-screen">
              <img alt="" className='absolute -top-10 transform scale-100' src="/images/blurred_1.png" />
              <img alt="" className='absolute md:left-20 md:ml-64 top-20 mt-48 transform md:scale-125' src="/images/blurred_2.png" />
              <img alt="" className='absolute right-32 transform scale-125' src="/images/blurred_1.png" />
              <img alt="" className='absolute right-5 top-20 transform mt-44 md:scale-150' src="/images/blurred_2.png" />
        </div>
       
        <div className="mt-24 grid md:grid-cols-2 gap-4 relative">         
          <div className="w-full h-full">
            <div className="text-base font-medium mb-3 text-gray-700 display flex items-center"><span className="w-6 h-6 mr-1"><img src="/images/x-circle.svg" className="w-full" /></span>Before Emailkick</div>
            <div className="w-full bg-gray-100 bg-opacity-75 border-gray-100 border rounded-md p-2 glassbox shadow-sm">
              <img alt="before-emailkick" src="/images/before-emailkick-veritical-new.svg"></img>
            </div>
          </div>

          <div className="w-full">
            <div className="text-base font-medium mb-3 text-gray-700 display flex items-center "><span className="w-6 h-6 mr-1"><img src="/images/tick.svg" className="w-full" /></span>After Emailkick</div>
              <div className="w-full bg-gray-100 bg-opacity-75 rounded-md p-2 glassbox border-gray-100 border shadow-sm">
                <img  alt="after-emailkick" src="/images/after-emailkick-vertical-new.svg"></img>
              </div>
              
            </div>
        </div>
      
      </div>
    </section>



    <section className="md:my-32 my-8">
      <div className="md:max-w-5xl mx-auto ">
        <div className="rounded-lg border-gray-50 bg-white px-8 py-0 flex items-center md:flex-row flex-col mb-12 md:mb-0">
          <div className="col-3 md:mr-12 mb-6 ">
            <img  alt="Magdy-Ashraf" className="rounded-full w-full -top-2 border-gray-100 border-2 shadow-md md:shadow-2xl" src={`/magdy-dp.jpeg`}></img>
          </div>
          <div className="md:col-9 col-12">
            <p className="md:text-2xl text-base font-light leading-relaxed tracking-wide pb-2 border-b border-gray-200">"We shipped our email infrastructure in a day thanks to Emailkick. We're now sending emails to thousands of our customers without taking days of development to integrate AWS SES."</p>
            <div className="md:text-lg text-sm mt-3 font-normal block text-gray-400"><span className="font-medium text-gray-800 mr-2 block  md:inline-block">Magdy Ashraf</span>Co-Founder & CEO, Potafo</div>
          </div>
        </div>
      </div>
    </section>

    







    <section className="feat-section mt-24 md:mt-24">
      <div className="max-w-6xl mx-auto md:px-2 px-4">
        <div className="mx-auto max-w-2xl mb-8"> 
          <h2 className="md:text-center text-3xl font-medium text-heading-blue">Create email designs in minutes</h2>
          <p className="quiet display  text-lg md:text-center mt-4 leading-normal">Design beautiful web and mobile emails without writing any code. Our drag and drop builder gives you total freedom, letting you build pixel-perfect, responsive designs.</p>
        </div>

        <div className="relative  mt-24" >

          <div className="blurred-class relative max-w-screen z-0 ">
                <img alt="blurred-bg" className='absolute -top-20 transform -mt-24 left-2 scale-100' src="/images/blurred_1.png" />
                <img alt="blurred-bg" className='absolute left-20 md:ml-64 -top-10 transform scale-50 md:scale-125' src="/images/blurred_2.png" />
                {/* <img className='absolute right-32 transform scale-100' src="/images/blurred_1.png" /> */}
                <img alt="blurred-bg" className='absolute right-5 transform  md:scale-150' src="/images/blurred_2.png" />
          </div>
      
          <div style={{ height:'440px' }} className="z-20 relative max-w-6xl mx-auto overflow-hidden rounded-3xl bg-black bg-opacity-70 glassbox pt-8 md:pt-12  px-2 md:px-10 shadow-lg ">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              <div className="grid grid-cols-1 gap-6">
                <img  alt="emails designed using emailkick" src="/images/email-design-001.png"  data-sal="slide-up"   data-sal-easing="ease" className="w-full shadow-lg rounded-xl" />
              </div>

              <div className="grid grid-cols-1 gap-6">
                <img alt="emails designed using emailkick" src="/images/email-design-1.svg"  data-sal="slide-up"    data-sal-delay="100"  data-sal-easing="ease" className="w-full  shadow-lg rounded-xl" />
                <img  alt="emails designed using emailkick" src="/images/email-design-2.svg"  data-sal="slide-up"   data-sal-delay="200"  data-sal-easing="ease" className="w-full  shadow-lg rounded-xl" />

              </div>

              <div className="grid grid-cols-1 gap-6">
                <img  alt="emails designed using emailkick" src="/images/email-design-3.svg"  data-sal="slide-up"   data-sal-delay="300"  data-sal-easing="ease" className="w-full  shadow-lg rounded-xl" />
                <img  alt="emails designed using emailkick" src="/images/email-design-4.svg"  data-sal="slide-up"   data-sal-delay="350"  data-sal-easing="ease" className="w-full  shadow-lg rounded-xl" />

              </div>

              <div>
                <img alt="emails designed using emailkick" src="/images/email-design-4.svg"  data-sal="slide-up"   data-sal-delay="350"  data-sal-easing="ease" className="w-full  shadow-lg rounded-xl block md:hidden" />

              </div>
            </div>
          </div>
          
        </div>
      </div>


      <div className="text-center mt-12">
        <Link to="/email-designer/" className="text-center text-base text-blue-800 hover:underline">Learn more about the editor ⟶</Link>
      </div>
    </section>


    <section className="feat-section mt-24" style={{ backgroundColor:'#f7f9fd' }}>
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-wrap items-center feat-blu py4">
        
          <div className="md:w-1/2 md:px-2 px-4">
            <h2 className="mb3 display strong xhuge line-height-2 highlight-text text-heading-blue">Send an email with a few lines of code</h2>
            <h3 className="quiet display line-height-3 mb2">Get up and running today. We have a robust set of APIs and comprehensive documentation so you can spend less time adding email into your app and more time building cool stuff.</h3>
          </div>

          <div className="md:w-1/2 mx-auto">
              <div className="blurred-class relative max-w-screen z-0">
                <img alt="blurred-img" className='absolute -top-20 transform  left-2 md:scale-125' src="/images/blurred_1.png" />
              </div>
            {/* <Gist id={'e8478428980695c900ce00d51bcb3e8f'} file={'gistfile1.txt'} /> */}
            {/* <img alt="EmailKick code" className='col-12' src={'/emails-04.png'} /> */}
            <div data-sal="slide-up" data-sal-delay='100' className="w-full rounded-2xl bg-white bg-opacity-90 shadow-lg border border-gray-200 relative z-20 glassbox-non-radius">
              <div className="bg-gray-50 px-8 py-3 text-base border-b bg-opacity-75 border-gray-200 rounded-t-2xl"><code className="text-gray-400">sendEmail.code</code></div>
              <div className="py-2 md:px-6 px-2 text-xs md:text-base rounded-2xl mx-auto" >
                <CodeHighlighter code={curlCommand} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>





    {/* <section className="feat-section">
      <div className="mx-auto max-w-6xl mt-24 py-8 px-4 md:px-14">
        <div className="mx-auto max-w-2xl mb-8"> 
          <h2 className="md:text-center text-3xl font-medium text-heading-blue">Hassle free API that does all the work for you.</h2>
          <p className="text-gray-600 display  text-lg md:text-center mt-4 leading-normal">Stop writing endless code and services to send simple emails. <br /> Emailkick API handles all the <span className="opacity-100">💩</span> work to make sure your email reaches your user. From HTML generation to sending email using AWS SES</p>
        </div>

        <div className="blurred-class relative max-w-screen">
              <img className='absolute -top-10 transform scale-100' src="/images/blurred_1.png" />
              <img className='absolute md:left-20 md:ml-64 top-20 mt-48 transform md:scale-125' src="/images/blurred_2.png" />
              <img className='absolute right-32 transform scale-125' src="/images/blurred_1.png" />
              <img className='absolute right-5 top-20 transform mt-44 md:scale-150' src="/images/blurred_2.png" />
        </div>
       
        <div className="mt-24 grid md:grid-cols-2 gap-4 relative">         
          <div className="w-full h-full">
            <div className="text-base font-medium mb-3 text-gray-700 display flex items-center"><span className="w-6 h-6 mr-1"><img src="/images/x-circle.svg" className="w-full" /></span>Before Emailkick</div>
            <div className="w-full bg-gray-100 bg-opacity-75 border-gray-100 border rounded-md p-2 glassbox shadow-sm">
              <img src="/images/before-emailkick-veritical-new.svg"></img>
            </div>
          </div>

          <div className="w-full">
            <div className="text-base font-medium mb-3 text-gray-700 display flex items-center "><span className="w-6 h-6 mr-1"><img src="/images/tick.svg" className="w-full" /></span>After Emailkick</div>
              <div className="w-full bg-gray-100 bg-opacity-75 rounded-md p-2 glassbox border-gray-100 border shadow-sm">
                <img src="/images/after-emailkick-vertical-new.svg"></img>
              </div>
              
            </div>
        </div>
      
      </div>
    </section>

 */}




    {/* <section className="feat-section" style={{ marginTop:'80px' }} >
      <div className="max-width-4 mx-auto">
        <div className="flex flex-wrap items-center feat-pin py1 px0">

          <div className="md-col-7">
            <img className='col-12' alt="EmailKick sample email" src={require('../assets/images/emails-03.png')} />
          </div>


          <div className="md-col-5 px3">
            <h2 className="text-6xl font-light">Create designs in minutes not days</h2>
            <h3 className="quiet display line-height-3 mt-3">Design beautiful web and mobile emails without writing any code. Our drag and drop builder gives you total freedom, letting you build pixel-perfect, responsive designs.</h3>
          </div>
        
        </div>
      </div>
    </section> */}

{/* 
    <section className="feat-section" style={{ margin:'80px 0' }} >
      <div className="max-width-4 mx-auto">
        <div className="flex flex-wrap items-center feat-pin py1 px0">

          <div className="md-col-12">

            <div className="md-col-10  mb4 center px3 mx-auto">
              <h2 className="mb1 display strong xhuge line-height-2 highlight-text">Intutive Email Editor with Rich Elements</h2>
              <h3 className="quiet display line-height-3 mb2 px4 mx2">Create mordern looking emails pre-designed by cool designers to make your brand stand out.</h3>
            </div>

            <div id="player-overlay">
              <video width="100%" video="100%" poster={require('../assets/video-poster.jpg')} loop muted autoPlay style={{ width:'100%', height:'100%' }}>
                <source src={require('../assets/emailkick-dashboard.mp4')} />
              </video>    
            </div>          
          </div>


          
        
        </div>
      </div>
    </section> */}





    {/* <section>
      <div className="max-w-5xl mx-auto mt-24">
        <div className="rounded-lg border-gray-50 bg-white px-8 py-0 flex items-center">
          <div className="col-3 mr-12 ">
            <img className="rounded-full w-full -top-2 border-gray-100 border-2 shadow-2xl" src={`/boby-dp.jpeg`}></img>
          </div>
          <div className="col-9">
            <p className="text-2xl font-light leading-relaxed tracking-wide pb-2 border-b border-gray-200">"Emailkick allows anyone to create beautiful email templates easily. Our team has been loving it. The whole setup process was fast and easy and we got started in no time"</p>
            <div className="text-lg mt-3 font-normal block text-gray-400"><span className="font-medium text-gray-800 mr-2">Bobby Isac</span> Founding member, Early stage startup</div>
          </div>
        </div>
      </div>
    </section> */}


    <section className="md:mt-24 my-8">
      <div className="md:max-w-5xl mx-auto ">
        <div className="rounded-lg border-gray-50 bg-white px-8 py-0 flex items-center md:flex-row flex-col mb-12 md:mb-0">
          <div className="col-3 md:mr-12 mb-6 ">
            <img  alt="Testimonial by Boby Isac" className="rounded-full w-full -top-2 border-gray-100 border-2 shadow-md md:shadow-2xl" src={`/boby-dp.jpeg`}></img>
          </div>
          <div className="md:col-9 col-12">
            <p className="md:text-2xl text-base font-light tracking-wide pb-2 border-b border-gray-200">"Emailkick allows anyone to create beautiful email templates easily. Our team has been loving it. The whole setup process was fast and easy and we got started in no time"</p>
            <div className="md:text-lg text-sm mt-3 font-normal block text-gray-400"><span className="font-medium text-gray-800 mr-2 block md:inline-block">Bobby Isac</span> Founding member, Early stage startup</div>
          </div>
        </div>
      </div>
    </section>



    <section className="feat-section mt-8 md:mt-24 py-12 md:py-24"  style={{ backgroundColor:'#f7f9fd' }}>

      <div className="max-w-6xl mx-auto">
          <div className="relative z-0">
              <img alt="blurred-bg" className="absolute transform scale-100 -mt-24" src="/images/blurred_2.png" />
              <img alt="blurred-bg" className="absolute transform md:scale-150 right-2 -mt-24 opacity-50" src="/images/blurred_2.png" />

              {/* <img className="absolute transform scale-50 -mt-48 right-0 z-0" src="/bg-left.jpg" /> */}

          </div>  

          <div className="grid md:grid-cols-3 gap-4 relative z-10 px-4">
            <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl p-6 border-2 border-gray-50 shadow-sm" >
              <img alt="unsubscribe icon" src="/icons8-unsubscribe.svg" className="w-10 h-10 mb-2" />
              <h3 className="text-lg mb-2 font-medium">Unsubscriber Management</h3>
              <p className="text-sm leading-relaxed text-gray-600">Emailkick allows you to add unsubscription link to any outgoing emails without any programming on your end. You can enable this in your template setting.</p>
            </div>

            <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl p-6 border-2 border-gray-50 shadow-sm" >
              <img alt="template icon" src="/icons8-venn-diagram.svg" className="w-10 h-10 mb-2" />
              <h3 className="text-lg mb-2 font-medium">Dynamic Templates</h3>
              <p className="text-sm leading-relaxed text-gray-600">Change the contents of your email easily with dynamic text with Emailkick. Send curated emails to your users.</p>
            </div>


            <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl p-6 border-2 border-gray-50 shadow-sm" >
              <img alt="infra icon" src="/icons8-favorites-shield.svg" className="w-10 h-10 mb-2" />
              <h3 className="text-lg mb-2 font-medium">Secure Infrastructure</h3>
              <p className="text-sm leading-relaxed text-gray-600">All our code and databases are stored in secure AWS server. We make sure our security protocols are of high standards</p>
            </div>



           
          </div>
      </div>

    </section>

{/* 
        <section className="feat-section mt-24" >
      <div className="max-width-4 mx-auto">
        <div className="flex flex-wrap items-center py2 px0">

           <div className="md-col-7">
            <img className='col-12' alt="EmailKick dashboard" src={'/emails-02.png'} />
          </div>


          <div className="md-col-5 px3">
            <h2 className=" mb3 display strong huge highlight-text">All your email templates fully organised.</h2>
            <h3 className="quiet display line-height-3 mb2">Stop wasting valuable time writing custom code to start sending or edit your email. Build bueatiful looking emails using drag and drop editor and organise all your mails in one place.</h3>
          </div>

       
         
        </div>
         
      </div>
    </section> */}


        <section className="feat-section mt-12 md:mt-24">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-wrap items-center feat-blu py4">
        
          <div className="md-col-6 md:px-2 px-4 relative z-10">
            <h2 className="mb3 display strong xhuge line-height-2  highlight-text text-left">Manage all your email templates and get the history of the emails sent</h2>
            <h3 className="quiet display line-height-3 mb2 text-left">Design, edit and manage all your email templates in one place. Design emails for every use case and get a comprehensive overview of every email you send. See the timeline, status, and more. </h3>
            <Link className="text-blue-700 text-base hover:text-blue-800 hover:underline" to="/ses-template-manager/">Learn more about template manager →</Link>
          </div>

          <div className="md-col-6 mx-auto">
            {/* <Gist id={'e8478428980695c900ce00d51bcb3e8f'} file={'gistfile1.txt'} /> */}
            {/* <img className='col-12' alt="EmailKick history dashboard" src={'/emails-06.png'} /> */}

            <div className="relative z-0">

              <img alt="bg" className="absolute transform scale-100  md:-right-20 z-0 opacity-80" src="/bg-left.jpg" />
              <img alt="bg" className='absolute top-20 transform z-1 -mt-24 left-2 md:scale-125 opacity-30' src="/images/blurred_1.png" />
          </div>  
            <div className="grid-col-1 gap-10 relative z-0 mx-auto md:mt-0 mt-8">


              {HistoryBlockData.map(his => <HistoryEmailBlock {...his} />)}

            </div>

          </div>


          
        </div>
      </div>
    </section>









     <section className="feat-section mt-8 md:mt-24">
      <div className="max-w-6xl mx-auto">
        <div className="row feat-pnk md:px-3 px-4  center">
            <img alt="Email Amazon AWS SES connection" className='md:w-1/2 mx-auto' src={'/emails-05.png'} />

            <h2 className="mb2 display strong xhuge line-height-2  highlight-text text-left md:text-center">Seamlessly Connect to AWS SES.</h2>
            <h3 className=" display line-height-3 mb2 md:px-6 text-left md:text-center" >Cut your email delivery cost to a fraction using Amazon Web Service Simple Email Service. It takes just seconds to connect.</h3>
            <div className="my-4 flex md:flex-row flex-col items-center justify-center">
              <div className="cta-bottom my4">
                <a href='https://app.emailkick.com/register/' className=" bg-red-500 hover:bg-red-600 text-white strong px-8 py-4 text-lg font-medium rounded-xl  duration-100 shadow-lg text-center">Get started for free</a>
              </div>
              <div className="ml-2">
                <Link className="px-6 py-4 bg-gray-50 border border-gray-200 rounded-lg font-medium text-base text-gray-700 hover:bg-gray-100 shadow-sm" to="/demo/">Schedule a demo</Link>
              </div>
            </div>
            
        </div>
      </div>
    </section>



    <section className="mt-24">
      <div className="max-w-5xl mx-auto">
        <div className="flex items-center justify-center gap-10">
          {/* <div className="bg-blue-50 rounded-lg p-4">
            <h4 className="text-gray-900 text-lg font-medium">Looking to migrate to Amazon SES?</h4>
            <p className="text-base mt-2 text-gray-600">Learn how to migrate your existing email platform to Amazon SES and integrate with Emailkick.</p>
            <Link className="mt-2 block text-sm text-blue-800 hover:underline" to="/">Read migration documentation</Link>
          </div> */}


          <div style={{"background-color": "rgb(255, 208, 47)"}} className="max-w-lg rounded-lg p-4">
            <h4 className="text-black text-lg font-medium">Want to use Emailkick with existing email provider?</h4>
            <p className="text-base mt-2 text-gray-800">We are rolling out more features to integrate more email providers. Signup to our Substack list to know first.</p>
            <a href="https://emailkick.substack.com" className="mt-4 center inline-block text-sm text-white  px-4 py-2 rounded bg-red-500 hover:bg-red-600 shadow-sm" to="/">Join now</a>
          </div>
        </div>
      </div>
    </section>




  </Layout>
)

export default IndexPage
